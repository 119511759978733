import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import DescriptionIcon from "@material-ui/icons/Description";

import Oakland from "./images/oakland.jpg";
import KOH from "./images/koh.png";
import Hayward from "./images/hayward.jpg";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";

const imgStyle = {
  height: 65,
  // maxWidth: "80%",
  objectFit: "cover",
  display: "block",
  margin: "0 auto",
  marginTop: 10,
  marginBottom: 10,
};

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const ami50 = [
45700,
52200,
58750,
65250,
70500,
75700,
80950,
86150
];

const ami30 = [
  27450,
  31350,
  35250,
  39150,
  42300,
  45450,
  48550,
  51700
];

const ami80 = [
  76760,
  87700,
  98650,
  109600,
  118400,
  127150,
  135950,
  144700
];

export default function SelectCity(data) {
  const [open, setOpen] = React.useState(false);

  const [renterLandlord, setRenterLandlord] = React.useState({type:"", income50ami: false, deedRestricted: false, buttonClicked: false});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateData = (field, value) => {
    data.setFormData({ ...data.formData, ready: true });
  };

  const hCutoff =
    new Date().toUTCString() >=
    new Date(Date.UTC(2020, 4, 20, 1, 0, 0)).toUTCString();

  console.log("data", data);

  const { whatCity, error } = data.formData;

  const { setActiveStep } = data;

  // if (open) {
  //   setTimeout(()=>{
  //     if (document.getElementsByClassName('MuiDialog-root').length > 0) {
  //     document.getElementsByClassName('MuiDialog-root')[0].id='testing1234';
  //     }
  // },1000)
  // }

  return (
    <React.Fragment>
      <Dialog
        open={open && whatCity == "Oakland"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick={async () => {
          setRenterLandlord({type:"", income50ami: false, deedRestricted: false, buttonClicked: false})
          handleClose();
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">{""}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          
          { renterLandlord.buttonClicked && renterLandlord.type == "landlord"  && 
          <Grid container spacing={1} style={{paddingTop: '2em', paddingBottom: '2em'}}>
            <Grid item xs={12}>
            { renterLandlord.deedRestricted && <div style={{paddingBottom: '3em', textAlign: 'center', fontSize: '1.5em'}}>Oakland's Emergency Rental Assistance Program prioritizes properties that are 100% restricted affordable housing.<br/><br/> To apply, please email:  <a href={'mailto:HousingAssistance@oaklandca.gov?subject=ERAP for Affordable Housing'}>HousingAssistance@oaklandca.gov</a> to request a Property Profile Application Form.  Please note: "ERAP for Affordable Housing" in subject line.  This Application Form will include instructions.​</div> }
            { !renterLandlord.deedRestricted && <div style={{paddingBottom: '3em', textAlign: 'center', fontSize: '1.5em'}}>Oakland’s Emergency Rental Assistance Program prioritizes properties that are 100% restricted affordable housing.  However, the State of CA is partnering with Oakland to offer emergency rental assistance to landlords with properties in Oakland whose tenants earn 80% Area Median Income or less. <br/><br/> Please visit <a href={'http://housingiskey.com'}>www.housingiskey.com</a>, and click on CA COVID-19 Rent Relief for more information.</div> }
            </Grid>
          </Grid>
          }
          { !renterLandlord.buttonClicked && renterLandlord.type == "landlord" &&
          <Grid container spacing={1} style={{paddingTop: '2em', paddingBottom: '2em'}}>
            <Grid item xs={12}><div style={{paddingBottom: '3em', textAlign: 'center', fontSize: '1.5em'}}>
              If you’ve previously applied to and/or received assistance from the state program, please go to <a href="https://housing.ca.gov/">https://housing.ca.gov/</a><br/><br/>
              If you are seeking rental assistance for a tenant who has not applied already through the local or state program you can receive support by going to <a href="http://bit.ly/oakland-rent-help">http://bit.ly/oakland-rent-help</a></div></Grid>
            {/* <Grid item xs={12} sm={6}>            
              <div aria-label="No" className="oaklandButton" onClick={()=>setRenterLandlord({...renterLandlord, buttonClicked: true, deedRestricted: false})}>
                <strong>No</strong>
              </div>
            </Grid>    
            <Grid item xs={12} sm={6}>
              <div aria-label="No" className="oaklandButton" onClick={()=>setRenterLandlord({...renterLandlord, buttonClicked: true, deedRestricted: true})}>
                <strong>Yes</strong>
              </div>
            </Grid>   */}
          </Grid>
          }

          
          { renterLandlord.buttonClicked && renterLandlord.type == "renter" && !renterLandlord.income50ami && 
          <Grid container spacing={1} style={{paddingTop: '2em', paddingBottom: '2em'}}>
            <Grid item xs={12}>
              <div style={{paddingBottom: '3em', textAlign: 'center', fontSize: '1.5em'}}>
                Oakland’s Emergency Rental Assistance Program only serves households below 80% of Area Median Income.
                <br /><br />
                Thank you for your inquiry.
            ​</div>
            </Grid>
          </Grid>
          }
          { !renterLandlord.buttonClicked && renterLandlord.type == "renter" &&
          <Grid container spacing={1} style={{paddingBottom: '2em'}} className="translate">
            <Grid item xs={12}>
              <div style={{paddingBottom: '0.5em', textAlign: 'center', fontSize: '1.5em'}}>
                Is your TOTAL CURRENT household income less than 80% Area Media Income?
              </div>
              <Grid container spacing={1} style={{paddingBottom: 10}}>

                <Grid item xs={5} style={{textAlign: 'right'}}>People in Household</Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5}>80% of Median</Grid>
                { ami80.map((ami,i)=>[
                    <Grid key={`ami-${ami}-${i}-1`} item xs={5} style={{textAlign: 'right'}}>{i + 1}</Grid>,
                    <Grid key={`ami-${ami}-${i}-2`} item xs={2}></Grid>,
                    <Grid key={`ami-${ami}-${i}-3`} item xs={5}>{formatter.format(ami).replace('.00', '')}</Grid>,
                ])}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} style={{textAlign: 'right'}}>
              <div aria-label="No" className="oaklandButton" onClick={()=>setRenterLandlord({...renterLandlord, buttonClicked: true, income50ami: false})}>
                <strong>No</strong>
              </div>
            </Grid>    
            <Grid item xs={12} sm={6} style={{textAlign: 'left'}}>
              <div  aria-label="Yes" className="oaklandButton" onClick={()=>{ updateData(); handleClose();} }>
                <strong>Yes</strong>
              </div>
            </Grid>  
          </Grid>
          }
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              // updateData("whatCity", "");
              setRenterLandlord({type:"", income50ami: false, deedRestricted: false, buttonClicked: false})
              handleClose();
            }}
            color="primary"
          >
            Cancel
          </Button>
          {/* <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button> */}
        </DialogActions>
      </Dialog>

      {/* <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <img style={imgStyle} src={Oakland} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img style={imgStyle} src={KOH} />
        </Grid>
      </Grid> */}

      {/* <Typography variant="h6" gutterBottom style={{ color: "#444" }}>
        
      </Typography> */}
      <Grid container spacing={1}>
        {/* <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={Emeryville} />
        </Grid> */}
        {/* <Grid item xs={12} sm={4}>
          <img style={imgStyle} src={Hayward} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <img style={imgStyle} src={KOH} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <img style={imgStyle} src={Oakland} />
        </Grid>
        <Grid item xs={12} style={{padding: 25}}>
          <Typography id={"message"} style={{ color: "red" }}>&nbsp;</Typography>
        </Grid> */}
      {/* <Typography
        style={{ color: "#666", textDecoration: "underline", marginTop: 25 }}
      >
      If one or more individuals in your household meet all of the following, you are eligible to apply:
      </Typography> */}
      <Grid container spacing={3} style={{paddingTop: '', paddingBottom: '5em'}}>




          {/* <Grid item xs={12} sm={6}>
            <div onClick={()=>{setOpen(true); setRenterLandlord({...renterLandlord, type: 'landlord'})}} aria-label="I'm a Landlord" className="oaklandButton">
				      <strong>I'm a Landlord</strong>
            </div>
          </Grid>    
          <Grid item xs={12} sm={6}>
            <div onClick={()=>{setOpen(true); setRenterLandlord({...renterLandlord, type: 'renter'})}} aria-label="I'm a Renter" className="oaklandButton">
				      <strong>I'm a Renter</strong>
            </div>
          </Grid>         */}
          <Grid item xs={12}>

          <Typography
            style={{ color: "#666", marginTop: 0, textAlign: 'left', fontWeight: 'bold' }}
          >
            As of March 31, 2022, the ERAP application website is no longer accepting applications. Alternative resources that may offer assistance: 
          </Typography>

            <List className="introList">
              <ListItem>
              <ListItemIcon className={'smallIcon'}>
                <FiberManualRecordIcon />
              </ListItemIcon>              
                <ListItemText
                  primary={<div>Limited Financial Assistance through <a href="https://www.alamedacountysocialservices.org/our-services/Shelter-and-Housing/Other-Support/season-of-sharing?msclkid=07443246aada11ec9e378d9884ccacdb" target="_blank">Seasons of Sharing call (510)272-3700 for eligibility Season of Sharing (alamedacountysocialservices.org)</a></div>}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Landlord tenant mediation and arbitration is available through the City of Oakland Rent​"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<div>Adjustment Program; call (510)238-3721 or visit <a href="https://www.oaklandca.gov/topics/rent-adjustment-program" target="_none">City of Oakland | Rent Adjustment Program (oaklandca.gov)</a></div>}
                />
              </ListItem>

              <ListItem className="multiLevel">
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Legal Consultations are available through:"}
                  secondary={
                  <List>
                    <ListItem>
                      <ListItemIcon className={'smallIcon'}>
                        <RadioButtonUnchecked />
                      </ListItemIcon>              
                        <ListItemText
                          primary={<div>HERA (property owners) <a href="https://www.heraca.org/" target="_blank">Housing and Economic Rights Advocates (heraca.org)</a></div>}
                        />
                      </ListItem>

                    <ListItem>
                      <ListItemIcon className={'smallIcon'}>
                        <RadioButtonUnchecked />
                      </ListItemIcon>              
                        <ListItemText
                          primary={<div>Centro Legal de La Raza (tenant applicants) <a href="https://www.centrolegal.org/" target="_blank">https://www.centrolegal.org/</a></div>}
                        />
                      </ListItem>

                    <ListItem>
                      <ListItemIcon className={'smallIcon'}>
                        <RadioButtonUnchecked />
                      </ListItemIcon>              
                        <ListItemText
                          primary={<div>East Bay Community Law Center <a href="https://ebclc.org/" target="_blank">https://ebclc.org/</a></div>}
                        />
                      </ListItem>

                                          <ListItem>
                      <ListItemIcon className={'smallIcon'}>
                        <RadioButtonUnchecked />
                      </ListItemIcon>              
                        <ListItemText
                          primary={<div>Oakland Lawyers In the Library <a href="https://oaklandlibrary.org/lawyers-in-the-library/" target="_blank">https://oaklandlibrary.org/lawyers-in-the-library/ </a></div>}
                        />
                      </ListItem>

                                          <ListItem>
                      <ListItemIcon className={'smallIcon'}>
                        <RadioButtonUnchecked />
                      </ListItemIcon>              
                        <ListItemText
                          primary={<div>Eviction Defense Center  <a href="https://www.evictiondefensecenteroakland.org/" target="_blank">https://www.evictiondefensecenteroakland.org/ </a></div>}
                        />
                      </ListItem>

                  </List>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<div><a href="https://acgov.org" target="_blank">SUPERIOR COURT OF CALIFORNIA, COUNTY OF ALAMEDA (acgov.org)</a></div>}
                />
              </ListItem>

              <ListItem className="multiLevel">
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Landlord and Tenant Mediation may be available through: "}
                  secondary={
                  <List>
                    <ListItem>
                      <ListItemIcon className={'smallIcon'}>
                        <RadioButtonUnchecked />
                      </ListItemIcon>              
                        <ListItemText
                          primary={<div>Seeds Community Resolution Clinic <a href="https://www.heraca.org/" target="_blank">https://www.seedscrc.org/</a></div>}
                        />
                      </ListItem>

                    <ListItem>
                      <ListItemIcon className={'smallIcon'}>
                        <RadioButtonUnchecked />
                      </ListItemIcon>              
                        <ListItemText
                          primary={<div>Updates to the City of Oakland’s Eviction Moratorium currently available here: <a href="https://www.oaklandca.gov/topics/rent-adjustment-program " target="_blank">https://www.oaklandca.gov/topics/rent-adjustment-program </a></div>}
                        />
                      </ListItem>

                  </List>
                  }
                />
              </ListItem>

            </List>
          <Typography
            style={{ color: "#666", marginTop: 25, textAlign: 'left' }}
          >
          <p style={{ fontWeight: 'bold' }}>IF YOU ALREADY APPLIED:</p>
          <p><b>For applications submitted prior to January 7, 2022.</b> We are now reviewing applications for eligibility and have assigned your case to a provider. You will be contacted within the next 3-4 weeks regarding eligibility and any required and additional documentation. No further action is needed at this time. Thank you for your patience.</p>
          <p><b>For applications submitted between January 7, 2022, and March 31, 2022.</b> Your application was automatically waitlisted. At this time, the City of Oakland has not received any additional federal resources to review or process waitlisted applicants. No further action from you is needed at this time. If the City of Oakland receives additional federal funds for emergency rental assistance, we will contact you in order of priority and need.  Thank you for your patience.   </p>

          <p>City of Oakland Emergency Rental Assistance Team </p>
          </Typography>
        </Grid>
      </Grid>
        <Grid item xs={12} style={{position: 'absolute', left: '-100000px'}}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal"}
            required
            error={error && (!whatCity || whatCity == "")}
          >
            <InputLabel shrink htmlFor="what-city">
              What city do you live in?
            </InputLabel>
            <InputLabel htmlFor="what-city">
              What city do you live in?
            </InputLabel>
            <Select
              native
              value={whatCity}
              onChange={(evt) => {
                console.log(evt.target);
                if (evt.target.value !== "Oakland") setOpen(true);
                if (
                  evt.target.value == "Hayward2" ||
                  (evt.target.value == "Emeryville" &&
                    new Date() >= new Date("06/16/2020"))
                ) {
                  // updateData("whatCity", null);
                  updateData("whatCity", evt.target.value);
                  document.getElementById(
                    "message"
                  ).textContent = `Our apologies but the Emeryville application portal has been closed due to the rent relief funding having been fully expended.`;
                } else {
                  document.getElementById("message").textContent = "";
                  updateData("whatCity", evt.target.value);
                  if (evt.target.value == "Oakland") document.location.href = "https://koh.bayareacs.org";
                  if (evt.target.value == "Fremont") document.location.href = "https://keepfremonthoused.bayareacs.org";
                }
              }}
              inputProps={{
                name: "whatCity",
                id: "what-city",
              }}
            >
              <option aria-label="None" value={""} />
              {/* <option value={"Emeryville"}>Emeryville</option> */}
              <option value={"Fremont"}>Fremont</option>
              {/* <option value={"Hayward"}>Hayward</option> */}
              <option value={"Oakland"}>Oakland</option>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal"}
            required
            error={error && (!whatCity || whatCity == "")}
          >
            <InputLabel shrink htmlFor="what-city">
              What city do you live in?
            </InputLabel>
            <InputLabel htmlFor="what-city">
              What city do you live in?
            </InputLabel>
            <Select
              native
              value={whatCity}
              onChange={(evt) => {
                console.log(evt.target);
                if (evt.target.value !== "Oakland") setOpen(true);
                if (
                  evt.target.value == "Hayward2" ||
                  (evt.target.value == "Emeryville" &&
                    new Date() >= new Date("06/16/2020"))
                ) {
                  // updateData("whatCity", null);
                  updateData("whatCity", evt.target.value);
                  document.getElementById(
                    "message"
                  ).textContent = `Our apologies but the Emeryville application portal has been closed due to the rent relief funding having been fully expended.`;
                } else {
                  if (document.getElementById("message"))
                  document.getElementById("message").textContent = "";
                  updateData("whatCity", evt.target.value);
                }
              }}
              inputProps={{
                name: "whatCity",
                id: "what-city",
              }}
            >
              <option aria-label="None" value={""} />
              <option value={"Fremont"}>Fremont</option>
              <option value={"Oakland"}>Oakland</option>
              <option value={"San Francisco"}>San Francisco</option>
            </Select>
          </FormControl>
        </Grid> */}


      {/* <Typography
        style={{ color: "#666", textDecoration: "underline", marginTop: 1 }}
      >
          You will receive an email confirmation of your submission.  Please do not submit more than 1 application per household.  Submitting more than 1 application could slow down your application processing.
      </Typography> */}


      </Grid>
    </React.Fragment>
  );
}