import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import DescriptionIcon from "@material-ui/icons/Description";

import OaklandDocs from './OaklandDocs.pdf';

const imgStyle = {
  height: 65,
  // maxWidth: "80%",
  objectFit: "cover",
  display: "block",
  margin: "0 auto",
  marginTop: 10,
  marginBottom: 10,
};

export default function Intro2(formData) {
  const { whatCity } = formData.formData;

  if (document.getElementsByClassName('translate').length > 0) {
    setTimeout(()=>{
      if (document.getElementsByClassName('translate').length > 0) {
      document.getElementsByClassName('translate')[0].id='testing12345';
      }
  },1000)
  }

  return (
    <React.Fragment>
      {/* <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={Emeryville} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={Hayward} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={KOH} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={Oakland} />
        </Grid>
      </Grid> */}
      {/* <div>
        <img style={imgStyle} src={cityImage()} />
      </div> */}
      <Typography
        style={{ color: "#666", textDecoration: "underline", marginTop: 1, fontSize: '1.5rem' }}
      >
      If one or more individuals in your household meet all of the following, you are eligible to apply:
      </Typography>
      <Grid container spacing={3} onClick={()=>window.open(OaklandDocs, '_blank')}>
        <Grid item xs={12} style={{cursor: 'pointer'}}>
          <List>
            <ListItem>
              <ListItemIcon>
                1.
              </ListItemIcon>
              <ListItemText
                primary="Have qualified for unemployment benefits or experienced a reduction in household income, incurred significant costs, or experienced other financial hardship during or due, directly or indirectly, to the coronavirus pandemic; and "
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                2.
              </ListItemIcon>
              <ListItemText 
              primary="Demonstrate a risk of experiencing homelessness or housing instability, which may include: " 
              secondary={<ul>
                <li>a past-due utility or rent notice or eviction notice;​ </li>
                <li>unsafe or unhealthy living conditions; or​ </li>
                <li>any other evidence of such risk</li>
              </ul>}/>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                3.
              </ListItemIcon>
              <ListItemText primary="Have a household income that is not more than 80% of the Area Median Income " />
            </ListItem>
          </List>
          <div>Even if you have doubts as to whether your financial hardships fit into the categories listed above, we encourage you to apply. <br/>Applicants will not be asked about their citizenship, nor will they be required to show proof of citizenship.</div>
        </Grid>
      </Grid>      
    </React.Fragment>
  );
}
