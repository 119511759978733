import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

// let files = {
//   id: { uploaded: false, name: "" },
//   lease: { uploaded: false, name: "" },
//   rentalPayments: { uploaded: false, name: "" },
//   incomeLossDoc: { uploaded: false, name: "" },
//   housingCrisisProof: { uploaded: false, name: "" },
//   landlordW9: { uploaded: false, name: "" },
// };

// const Missing = <HighlightOffIcon style={{ color: "red" }} />;
// const Uploaded = <CheckCircleOutlineIcon style={{ color: "green" }} />;

export default function LandlordForm(formData) {
  const updateData = (field, value) => {
    const data = formData;
    data.setFormData({ ...data.formData, [field]: value });
  };
  // const { files } = formData.fileData;
  const {
    id,
    lease,
    utility,    
    rentalPayments,
    incomeLossDoc,
    housingCrisisProof,
    landlordW9,
  } = formData.fileData;

  // console.log("files", formData, files);
  const handleFileUpload = (evt) => {
    console.log(
      "evt target name",
      evt.target.name,
      evt.currentTarget.name,
      evt.currentTarget.files
    );
    const { name } = evt.currentTarget;

    if (evt.currentTarget.files && evt.currentTarget.files.length > 0) {
      const keys = Object.keys(evt.currentTarget.files);
      const uploadedFiles = [];
      for (var i = 0; i < keys.length; i++) {
        const key = keys[i];

        const file = evt.currentTarget.files[key];
        if (file.size / 1000 > 3999) {
          file.toUpload = false;
        } else {
          file.toUpload = true;
        }
        uploadedFiles.push(file);
      }
      
      const f = {
        ...formData.fileData,
        [name]: {
          files: uploadedFiles,
          name,
          uploaded: uploadedFiles.filter(f=>f.toUpload).length > 0 ? true : false,
        },
      };
      console.log('files uploaded', uploadedFiles);
      console.log("new f", name, f);
      formData.setFileData(f);
    }
  };

  console.log("fileData", formData.fileData);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom style={{ color: "#666" }}>
        Documentation Upload
      </Typography>

      <Typography
        variant="h6"
        gutterBottom
        style={{
          color: "#666",
          fontSize: "1.0rem",
          fontWeight: 500,
          textAlign: 'left'
        }}
      >
        <p>Only <span style={{textDecoration: "underline", fontWeight: 600}}>one</span> form of verification is required for each category listed below.  This list shows examples of documents that you can use for verification.  It is not a list of all documents that may be accepted.​</p>
        <p>If you are unsure about the documents needed, submit the application without them and you will be contacted.</p>
      </Typography>

      <Typography
        variant="h6"
        gutterBottom
        style={{
          color: "#666",
          fontSize: "0.9rem",
          textDecoration: "underline",
          fontWeight: 500,
        }}
      >
        Uploads are limited to 4MB per file
      </Typography>

      <Grid container spacing={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <List>
              <ListItem>
                <ListItemIcon>
                  {!id || !id.uploaded ? (
                    <HighlightOffIcon style={{ color: "red" }} />
                  ) : (
                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Verification of Identity"
                  secondary={
                      <span>government issued ID (birth certificate, driver’s license, ID card, Muni ID, etc.); employment ID card; current school ID</span>
                  }
                />
                <Button variant="contained" component="label">
                  Upload File
                  <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    name={"id"}
                    onChange={handleFileUpload}
                  />
                </Button>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <ListItemIcon>
                    {!incomeLossDoc || !incomeLossDoc.uploaded ? (
                      <HighlightOffIcon style={{ color: "red" }} />
                    ) : (
                      <CheckCircleOutlineIcon style={{ color: "green" }} />
                    )}
                  </ListItemIcon>
                </ListItemIcon>
                <ListItemText
                  primary="Verification of Income"
                  secondary={
                    <span>current paycheck stub; letter from an employer stating current pay; current bank statements, social services provider letter testifying to primary applicant’s income; benefits award letter; monthly income history, etc.​</span>
                  }
                />
                <Button variant="contained" component="label">
                  Upload File
                  <input
                    multiple
                    type="file"
                    style={{ display: "none" }}
                    name={"incomeLossDoc"}
                    onChange={handleFileUpload}
                  />
                </Button>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <ListItemIcon>
                    {!lease || !lease.uploaded ? (
                      <HighlightOffIcon style={{ color: "red" }} />
                    ) : (
                      <CheckCircleOutlineIcon style={{ color: "green" }} />
                    )}
                  </ListItemIcon>
                </ListItemIcon>
                <ListItemText
                  primary="Verification of Residence"
                  secondary={
                    <span>lease agreement; letter from third party showing name/address; utility bill; social services provider letter testifying to residency</span>
                  }
                />
                <Button variant="contained" component="label">
                  Upload File
                  <input
                    multiple
                    type="file"
                    style={{ display: "none" }}
                    name={"lease"}
                    onChange={handleFileUpload}
                  />
                </Button>
              </ListItem>



              <ListItem>
                <ListItemIcon>
                  <ListItemIcon>
                    {!housingCrisisProof || !housingCrisisProof.uploaded ? (
                      <HighlightOffIcon style={{ color: "red" }} />
                    ) : (
                      <CheckCircleOutlineIcon style={{ color: "green" }} />
                    )}
                  </ListItemIcon>
                </ListItemIcon>
                <ListItemText
                  primary="Verification of Rent Owed"
                  secondary={
                    <span>current signed lease listing current rent paid; written “pay or quit” or eviction notice; stipulation agreement; non-leaseholder verifications such as check stub or bank statement showing proof of regular rent paid; written confirmation by landlord or management agent verifying amount owed such as a rent ledger</span>
                  }
                />
                <Button variant="contained" component="label">
                  Upload File
                  <input
                    multiple
                    type="file"
                    style={{ display: "none" }}
                    name={"housingCrisisProof"}
                    onChange={handleFileUpload}
                  />
                </Button>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  {!utility || !utility.uploaded ? (
                    <HighlightOffIcon style={{ color: "red" }} />
                  ) : (
                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Verification of Utility Payments Owed"
                  secondary={
                      <span> utility bill/statement showing outstanding balance​</span>
                  }
                />
                <Button variant="contained" component="label">
                  Upload File
                  <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    name={"utility"}
                    onChange={handleFileUpload}
                  />
                </Button>
              </ListItem>

              {/* <ListItem>
                <ListItemIcon>
                  <ListItemIcon>
                    {!landlordW9 || !landlordW9.uploaded ? (
                      <HighlightOffIcon style={{ color: "red" }} />
                    ) : (
                      <CheckCircleOutlineIcon style={{ color: "green" }} />
                    )}
                  </ListItemIcon>
                </ListItemIcon>
                <ListItemText
                  primary="W9 from Landlord"
                  secondary={
                    landlordW9 &&
                    landlordW9.files &&
                    landlordW9.files.length > 0 ? (
                      <span>
                        {landlordW9.files
                          .filter((f) => f.toUpload)
                          .map((idFile) => idFile.name)
                          .join(", ")}
                        {landlordW9.files.filter((f) => !f.toUpload).length >
                        0 ? (
                          <span style={{ color: "red" }}>
                            <br />
                            Not Uploaded (file too large):
                            {landlordW9.files
                              .filter((f) => !f.toUpload)
                              .map((idFile) => idFile.name)
                              .join(", ")}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    ) : (
                      "Not Yet Uploaded"
                    )
                  }
                />
                <Button variant="contained" component="label">
                  Upload File
                  <input
                    multiple
                    type="file"
                    style={{ display: "none" }}
                    name={"landlordW9"}
                    onChange={handleFileUpload}
                  />
                </Button>
              </ListItem> */}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
