import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import CircularProgress from "@material-ui/core/CircularProgress";


export default function SubmissionForm(formData) {
  let esig = React.useRef();

  const {
    error,
    agreed,
    signedName,
    whatCity,
    submittedBy,
    agreedTrue,
    agreedFalsification,
    agreedAssistance,
    agreedCityConsent,
    agreedCommunications,
    agreedLandlord,
    agreedParticipate
  } = formData.formData;
  const { isSubmitting, setSubmitting } = formData;
  // console.log("isSubmitting", isSubmitting, setSubmitting, formData);
  return (
    <React.Fragment>
      {isSubmitting && (
        <div>
          {" "}
          <CircularProgress /> <div>Submitting Form - Please Wait</div>
        </div>
      )}
      {!isSubmitting && (
        <div>

          <Typography variant="h6" gutterBottom style={{ color: "#666" }}>
            Consent and Release of Information
          </Typography>
          <Grid container spacing={3} style={{textAlign: 'left'}}>
          <Grid item xs={12}>
              <FormControl
                required
                error={error && (!agreedTrue || agreedTrue !== "yes")}
              >
              <FormControlLabel
                label={'By submitting this application, I certify under penalty of perjury that all information provided in this application is true, correct, accurate and complete, and I will provide additional documentation as required to support any representations made in this application.'}
                control={
                  <Checkbox
                    color="secondary"
                    name="agreed"
                    control={agreedTrue.toString()}
                    value={agreedTrue ? "yes" : false}
                    checked={agreedTrue}
                    onChange={(evt, agreedTrue) => {
                      formData.setFormData({ ...formData.formData, agreedTrue });
                    }}
                  />
                }
              />
              </FormControl>
          </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label={'I further acknowledge that falsification of any information or any material falsehoods or omissions in the Application, including knowingly seeking duplicative benefits, is subject to state and federal penalties including fines and criminal prosecution. It could also result in denial of my application or in a government agency, its agents, or employees nullifying my application and seeking to reclaim any financial assistance that I received.'}           
                control={
                  <Checkbox
                    color="secondary"
                    name="agreedFalsification"
                    control={agreedFalsification.toString()}
                    value={agreedFalsification ? "yes" : false}
                    checked={agreedFalsification}
                    onChange={(evt, agreedFalsification) => {
                      formData.setFormData({ ...formData.formData, agreedFalsification });
                    }}
                  />
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label={'I acknowledge that by submitting this application, I am in no way guaranteed to received financial assistance.'}
                control={
                  <Checkbox
                    color="secondary"
                    name="agreedAssistance"
                    control={agreedAssistance.toString()}
                    value={agreedAssistance ? "yes" : false}
                    checked={agreedAssistance}
                    onChange={(evt, agreedAssistance) => {
                      formData.setFormData({ ...formData.formData, agreedAssistance });
                    }}
                  />
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label={'I give consent/authorization for the City of Oakland and its respective agents, employees and assigns, and Bay Area Community Services and All Home, as operators/administrators of the Regional Homelessness Prevention Network (“Network”), to share, disclose, analyze, and discuss all documentation and information provided in this application in furtherance of offering me assistance, to prevent duplication of benefits, or to present aggregate data about the Emergency Rental Assistance Program or the Homelessness Prevention Network.'}
                control={
                  <Checkbox
                    color="secondary"
                    name="agreedCityConsent"
                    control={agreedCityConsent.toString()}
                    value={agreedCityConsent ? "yes" : false}
                    checked={agreedCityConsent}
                    onChange={(evt, agreedCityConsent) => {
                      formData.setFormData({ ...formData.formData, agreedCityConsent });
                    }}
                  />
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label={'I acknowledge that electronic communications (e.g., text messages) are not secure; I hereby authorize any service provider who is working with me to communicate with me regarding this program using electronic communications and have provided the phone number in the application to enable text message communication. And, I understand that text messaging is to be used for the purposes of communicating on a limited basis and is not intended to provide additional support. If I am in need of additional support when I receive a text message, I understand that I should not respond by text to my service provider; rather, I should contact the service provider via phone, email, or at the office of the service provider during business hours. I understand that I can revoke this provision regarding text message communications at any time during service by informing the service provider that I no longer want to receive text messages.'}
                control={
                  <Checkbox
                    color="secondary"
                    name="agreedCommunications"
                    control={agreedCommunications.toString()}
                    value={agreedCommunications ? "yes" : false}
                    checked={agreedCommunications}
                    onChange={(evt, agreedCommunications) => {
                      formData.setFormData({ ...formData.formData, agreedCommunications });
                    }}
                  />
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label={'I understand that if my landlord refuses to cooperate an emergency rental assistance payment may be made directly to me. If I receive a direct payment of funds, I attest under penalty of perjury that I shall provide the full amount of rental arrears to the landlord within 15 days of receipt of the funds, excluding Saturdays, Sundays, and judicial holidays.'}
                control={
                  <Checkbox
                    color="secondary"
                    name="agreedLandlord"
                    control={agreedLandlord.toString()}
                    value={agreedLandlord ? "yes" : false}
                    checked={agreedLandlord}
                    onChange={(evt, agreedLandlord) => {
                      formData.setFormData({ ...formData.formData, agreedLandlord });
                    }}
                  />
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label={<div><b>[Optional]</b> I agree to participate in the evaluation of the emergency rental assistance program and/or the regional homelessness prevention network, which will help improve future service delivery and potentially expand resources for services, rental support and affordable housing. This includes, but is not limited to, the City of Oakland sharing data with researchers from The University of Pennsylvania. Your willingness to participate in any future evaluation process will not affect your eligibility or selection for this program in any way. Participation in research or program evaluation is not required.</div>}
                control={
                  <Checkbox
                    color="secondary"
                    name="agreedParticipate"
                    control={agreedParticipate.toString()}
                    value={agreedParticipate ? "yes" : false}
                    checked={agreedParticipate}
                    onChange={(evt, agreedParticipate) => {
                      formData.setFormData({ ...formData.formData, agreedParticipate });
                    }}
                  />
                }
              />
            </Grid>

            <Grid item xs={4} implementation="css" />
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="agreed"
                    control={agreed.toString()}
                    value={agreed ? "yes" : false}
                    checked={agreed}
                    onChange={(evt, agreed) => {
                      formData.setFormData({ ...formData.formData, agreed });
                      if (agreed)
                        setTimeout(() => {
                          esig && esig.current.focus();
                        }, 250);
                    }}
                  />
                }
                label="Submission of this application by me or on my behalf by an application assistance provider indicates that I have reviewed all information in the application and I certify than under penalty of perjury that it is true and correct. I acknowledge receipt of all information provided to me in the paragraphs above. I consent to the release of information and other provisions as described above, and I understand that I have the right to revoke this consent in writing at any time provided to the City of Oakland at the following email address - housingassistance@oakland.gov​"
              />
            </Grid>
            <Grid item xs={4} implementation="css" />
            <Grid item xs={4}>
              <FormControl fullWidth className={"MuiFormControl-marginNormal"}>
                <InputLabel shrink htmlFor="submittedBy">
                  Form Submitted By
                </InputLabel>
                <InputLabel htmlFor="submittedBy">Form Submitted By</InputLabel>
                <Select
                  native
                  value={submittedBy}
                  // onChange={handleChange}
                  name={"submittedBy"}
                  onChange={(e) =>
                    formData.setFormData({
                      ...formData.formData,
                      submittedBy: e.target.value,
                    })
                  }
                  inputProps={{
                    name: "submittedBy",
                    id: "submittedBy",
                  }}
                >
                  <option aria-label="None" value="Myself">
                    MYSELF
                  </option>
                  <option aria-label="None" value="89301">
                    89301
                  </option>
                  <option aria-label="None" value="89302">
                    89302
                  </option>
                  <option aria-label="None" value="89303">
                    89303
                  </option>
                  <option aria-label="None" value="89304">
                    89304
                  </option>
                  <option aria-label="None" value="89305">
                    89305
                  </option>
                  <option aria-label="None" value="89306">
                    89306
                  </option>
                  <option aria-label="None" value="89307">
                    89307
                  </option>
                  <option aria-label="None" value="89308">
                    89308
                  </option>
                  <option aria-label="None" value="89309">
                    89309
                  </option>
                  <option aria-label="None" value="89310">
                    89310
                  </option>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                control
                required
                control={
                  formData.formData.signedName
                    ? formData.formData.signedName.toString()
                    : ""
                }
                disabled={!agreed}
                onChange={(evt) =>
                  formData.setFormData({
                    ...formData.formData,
                    signedName: evt.target.value,
                  })
                }
                value={signedName && agreed ? signedName : ""}
                inputRef={esig}
                focused={agreed}
                id="electronic-signature"
                name="electronicSignature"
                label="Type your full name for electronic signature submission"
                fullWidth
              />
            </Grid>
          </Grid>
                <Typography
        style={{ color: "#666", textDecoration: "underline", marginTop: '2rem', color: 'red' }}
      >
        You will receive an email confirmation of your submission.  Please do not submit more than 1 application per household.  Submitting more than 1 application could slow down your application processing.
        </Typography>
        </div>
      )}
    </React.Fragment>
  );
}
